<ng-container [ngSwitch]="customFieldGroup.type">
  <input
    *ngSwitchCase="customFieldType.TEXT"
    [formControl]="customFieldGroup.form.get('value')"
    class="form__element--input"
  />
  <decimal-input
    *ngSwitchCase="customFieldType.DECIMAL"
    [formControl]="customFieldGroup.form.get('value')"
    [defaultValue]="''"
    [fullWidth]="true"
  ></decimal-input>
  <date-input
    *ngSwitchCase="customFieldType.DATE"
    [formControl]="customFieldGroup.form.get('value')"
    [fullWidth]="true"
    appendTo="body"
  ></date-input>
  <money-input
    *ngSwitchCase="customFieldType.MONEY"
    [formControl]="customFieldGroup.form.get('value')"
    [fullWidth]="true"
  ></money-input>
  <input
    *ngSwitchCase="customFieldType.NUMERIC"
    [formControl]="customFieldGroup.form.get('value')"
    class="form__element--input"
    step="1"
    pattern="^-?[0-9]\d*(\.\d+)?$"
    type="number"
  />
  <a
    *ngSwitchCase="customFieldType.URL"
    class="text-primary-500 underline"
    target="_blank"
    [href]="customFieldGroup.form.get('value').value"
    >{{ customFieldGroup.form.get('value').value }}</a
  >
  <ng-container *ngSwitchCase="customFieldType.BOOLEAN">
    <label class="form__element--checkbox checkbox">
      <input type="checkbox" class="checkbox-input" [formControl]="customFieldGroup.form.get('value')" />
      <span class="checkbox-indicator"></span>
      <ng-container *ngIf="checkboxDescription">
        <span class="checkbox-description">&nbsp;{{ customFieldGroup.name }}</span>
      </ng-container>
    </label>
  </ng-container>
  <ng-container *ngSwitchCase="customFieldType.SELECT">
    <select [formControl]="customFieldGroup.form.get('value')" class="form-modal__input select">
      <option value=""></option>
      <ng-container *ngIf="customFieldGroup.hasDeletedSelectValue">
        <option [value]="customFieldGroup.form.get('value').value" disabled>
          {{ customFieldGroup.form.get('value').value }}
        </option>
      </ng-container>
      <option *ngFor="let fieldType of customFieldGroup.select_options" [value]="fieldType">
        {{ fieldType }}
      </option>
    </select>
  </ng-container>
  <ng-container *ngSwitchCase="customFieldType.MULTI_SELECT">
    <sb-select
      [formControl]="customFieldGroup.form.get('value')"
      [multiSelect]="true"
      [searchable]="true"
      [searchPlaceholder]="'Search' | translate"
      [showSelectAll]="true"
      [items]="customFieldGroup.multiSelectOptions"
    ></sb-select>
  </ng-container>
</ng-container>
