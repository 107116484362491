import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CurrencySymbolPipe } from '../pipes/currency-symbol.pipe';
import { DecimalPipe } from '../pipes/decimal.pipe';

export const MONEY_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MoneyInputComponent),
  multi: true,
};

@Component({
  selector: 'money-input',
  template: `
    <div class="input-group">
      <div class="input-group-addon">{{ '' | currencySymbol }}</div>
      <input
        class="form-modal__input-short decimal-input"
        [ngClass]="{ 'form-modal__input-short': !fullWidth, 'form-modal__input': fullWidth }"
        type="text"
        [ngModel]="displayValue"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="onChangeInput($event)"
        (keydown.ArrowUp)="incrementInteger($event)"
        (keydown.ArrowDown)="decrementInteger($event)"
        [disabled]="disabled"
        (blur)="onBlur()"
      />
    </div>
  `,
  providers: [MONEY_INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [CommonModule, FormsModule, CurrencySymbolPipe],
})
export class MoneyInputComponent implements ControlValueAccessor {
  @Input()
  decimals = 2;
  @Input()
  step = 1;
  @Input()
  fullWidth = false;

  displayValue: string;
  value: string;
  disabled: boolean;

  // internal functions to call when ControlValueAccessor
  // gets called
  onTouched: Function;
  onModelChange: Function;

  constructor(protected decimalPipe: DecimalPipe) {}

  incrementInteger(event: KeyboardEvent) {
    event.preventDefault();
    const value = parseFloat(this.value) + this.step;
    this.displayValue = this.transform(value.toFixed(this.decimals));
    this.onChange(value.toFixed(this.decimals));
  }

  decrementInteger(event: KeyboardEvent) {
    event.preventDefault();
    const value = parseFloat(this.value) - this.step;
    this.displayValue = this.transform(value.toFixed(this.decimals));
    this.onChange(value.toFixed(this.decimals));
  }

  onBlur() {
    this.displayValue = this.transform(this.value);
    this.onTouched();
  }

  // our custom onChange method
  onChangeInput(displayValue: string) {
    this.displayValue = displayValue;
    const value = !!displayValue ? this.parse(displayValue) : '';

    this.onChange(value);
  }

  protected onChange(value: string) {
    this.value = value;
    this.onModelChange(value);
  }

  protected parse(displayValue: string) {
    return this.decimalPipe.parse(displayValue, this.decimals);
  }

  protected transform(value: string) {
    return !!value ? this.decimalPipe.transform(value, this.decimals, false) : '';
  }

  // called by the reactive form control
  // writes the value to the local component
  // that binds to the "value"
  writeValue(value: string) {
    this.value = value;
    this.displayValue = this.transform(this.value);
  }

  // called by the reactive form control
  registerOnChange(fn: Function) {
    // assigns to our internal model change method
    this.onModelChange = fn;
  }

  // called by the reactive form control
  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  // called by the reactive form control
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
