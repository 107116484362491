import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CustomFieldsGroup, CustomFieldsType } from '@reducers/orm/custom-fields/custom-fields.model';
import { SelectComponent } from '@sb/ui';
import { DecimalInputComponent } from '@shared/decimal-input/decimal-input.component';
import { MoneyInputComponent } from '@shared/money-input.component';
import { DateInputComponent } from '@shared/sb-lib/calendar/date-input/date-input.component';

@Component({
  selector: 'app-custom-fields-types',
  templateUrl: './custom-fields-types.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DecimalInputComponent,
    DateInputComponent,
    MoneyInputComponent,
    TranslateModule,
    SelectComponent,
  ],
})
export class CustomFieldsTypesComponent {
  @Input()
  public customFieldGroup: CustomFieldsGroup;
  @Input()
  public checkboxDescription = false;

  public customFieldType = CustomFieldsType;
}
